@tailwind base;
@tailwind components;
@tailwind utilities;
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

p {
  font-size: 0.9rem;
}

.footer {
  font-family: arial;
}

button:focus {
  outline: unset;
  outline: none;
}

input:focus-visible {
  outline: unset;
  outline: none;
}
#main_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.switch_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: .4rem;
}
#switch_btn {


}

.view_page {
  height: 0;
  width: 0;
}
.view_page.active {
  height: 100%;
  width: 100%;
}
