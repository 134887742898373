body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
     /* position: sticky !important;
    background: white !important;
    z-index: 1 !important;
    width: 30% !important;
    right: 100% !important;
    left: 100% !important; */
}

iframe#webpack-dev-server-client-overlay{display:none!important}